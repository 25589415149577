<template>
  <v-app class="bg" t-data="app-bg" :style="backgroundImage">
    <v-alert-success :show="successAlert" :close="closeAlert" :link="link"></v-alert-success>
    <v-alert-error :show="errorAlert" :close="closeAlert" :msg="errorMsg"></v-alert-error>
    <v-popup-loading :isLoading="isLoading" />
    <v-main>
      <v-card
          class="mx-auto my-12"
          color="white"
          max-width="75%"
          style="padding: 5%"
      >
        <v-row class="justify-center">
          <v-img
            t-data="hospital-logo"
            :src="hospitalLogo"
            alt=""
            width="360"
            max-width="60%"
            max-height="30%">
          </v-img>
        </v-row>

        <v-row>
          <vue-phone-number-input
            t-data="info-input"
            v-model="phone"
            default-country-code="TH"
            @update="update"
          />
        </v-row>
        <v-row class="justify-center">
          <v-btn t-data="confirm-btn" style="margin-top: 20px;" color="primary" @click="generateLink" text>Confirm</v-btn>
        </v-row>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
  import VuePhoneNumberInput from 'vue-phone-number-input'
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import client from '@/apis/http-client'
  import AlertError from '../../components/AlertError'
  import AlertSuccess from '../../components/AlertGenerateLink'
  import PopupLoading from '../../components/PopupLoading'
  import {imageHospitalBg, imageHospitalLogo} from '@/constants/constants'

  export default {
    components: {
      'v-alert-error': AlertError,
      'v-alert-success': AlertSuccess,
      'v-popup-loading': PopupLoading,
      'vue-phone-number-input': VuePhoneNumberInput,
    },
    data() {
      return {
        phone: '',
        successAlert: false,
        errorAlert: false,
        isLoading: false,
        errorMsg: 'This mobile number is invalid or duplicate.',
        validatePhoneNumber: false,
        formattedNumber: '',
        link: '',
      }
    },
    computed: {
      backgroundImage() {
        return {
          "background-image": `url(${imageHospitalBg})`
        }
      },
      hospitalLogo() {
        return imageHospitalLogo
      },
    },
    methods: {
      generateLink: async function () {
        if (this.validatePhoneNumber) {
          try {
            this.isLoading = true
            let data = await client.secureHttpClient.post('/por/api/user/link', {phone_number: this.formattedNumber})
            this.link = data.data.data.url
            this.isLoading = false
            this.successAlert = true
          } catch (e) {
            this.isLoading = false
            this.errorAlert = true
          }
        } else {
          this.errorAlert = true
        }
      },
      closeAlert: function () {
        this.successAlert = false
        this.errorAlert = false
      },
      update: function (data) {
        this.formattedNumber = data.formattedNumber
        this.validatePhoneNumber = data.isValid
      }
    },
  }
</script>

<style lang="scss" scoped>
.theme--light.v-card {
  background-color: transparent;
}
.bg {
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: right top;
}
</style>

<style>
</style>
