<template>
    <v-dialog v-model="show" persistent max-width="400">
      <v-card style="justify-content: center" t-data="content">
        <center>
          <qrcode class="text-center" :value="displayText" :options="{ width: 200 }"></qrcode>
          <v-card-text t-data="card-text">Link: <a :href='displayText' id="text" ref="text">{{displayText}}</a></v-card-text>
          <div>
            <v-btn
                t-data="action-btn"
                id="alert-copy-link"
                depressed
                color="primary"
                text
                @click="copyUrl"
            >Copy Link</v-btn>
          </div>
          <div>
            <v-btn t-data="close-btn" id="alert-close" color="black darken-1" text @click="close">Close</v-btn>
          </div>

        </center>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import VueQrcode from '@chenfengyuan/vue-qrcode';

  export default {
    name: "AlertGenerateLink",
    components: {
      'qrcode': VueQrcode,
    },
    props: {
      show: {type: Boolean},
      close: {type: Function},
      link: {type: String},
    },
    methods: {
      closePopup() {
        this.close()
      },
      selectText(element) {
        let range;
        if (document.selection) {
          // IE
          range = document.body.createTextRange();
          range.moveToElementText(element);
          range.select();
        } else if (window.getSelection) {
          range = document.createRange();
          range.selectNode(element);
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
        }
      },
      copyUrl() {
        this.selectText(this.$refs.text)
        document.execCommand('copy');
      },
    },
    computed:{
      displayText() {
        return this.link
          ? `${window.location}/signup?url=${Buffer.from(this.link).toString('base64')}`
          : ''
      },
    }
  }
</script>


<style scoped>

</style>

